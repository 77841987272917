import React, { createRef } from 'react';
import { graphql } from 'gatsby';

import moment from 'moment';

import BackButton from '../../components/BackButton/BackButton';
import Hero from '../../components/Hero/Hero';
import Section from '../../components/Section/Section';
import ContentLayout from '../../components/ContentLayout/ContentLayout';
import SEO from '../../components/SEO/SEO';
import { sanitizer } from '../../util';
import TermWrapper from '../../components/TermWrapper/TermWrapper';

export default ({ data, pageContext }) => {
  const { page, subNav, activeSubNavLabel } = data;
  const { title, blogPostFields, dateGmt, blogTags, seo } = page;
  const { hero, author, postBody } = blogPostFields;

  return (
    <>
      <SEO seoData={seo} uri={page.uri} dateGmt={page.dateGmt} modifiedGmt={page.modifiedGmt} />

      <ContentLayout
        id="Blogpost"
        subNav={pageContext.subNavLocation ? subNav : null}
        activeSubNavLabel={activeSubNavLabel}
      >
        <Hero
          data={{
            background: hero?.image,
            backgroundMobile: hero?.imageMobile,
            theme: 'green',
            name: 'Blog_Hero_Top',
          }}
          pageContext={pageContext}
        />
        {renderBlog({ title, author, postBody, dateGmt, blogTags })}
      </ContentLayout>
    </>
  );
};

function renderBlog({ title, author, postBody, dateGmt, blogTags }) {
  if (postBody) {
    const ref = createRef();

    // TODO: localize date somehow globally with moment.js
    // moment.locale('de');

    return (
      <Section containerRef={ref} id="blogItem" class="container">
        <div className="wrapper blogpostwrapper">
          <div className="viewallwrapper">
            <div className="viewspace" />
            {/* TODO: maybe make dynamic somehow */}
            <BackButton ref={ref} url="/blog/" title="Back to Blog" />
          </div>
          <h1>{title}</h1>
          <div className="date">{moment(dateGmt).format('LL')}</div>
          <TermWrapper terms={blogTags} />
          <span>{author}</span>
          <div className="postbody" dangerouslySetInnerHTML={{ __html: sanitizer(postBody, true, {}) }} />
        </div>
      </Section>
    );
  }

  return null;
}

export const query = graphql`
  query blog(
    $databaseId: Int!
    $nextPage: Int
    $previousPage: Int
    $subNavLocation: WpMenuLocationEnum
    $activeSubPath: String
  ) {
    page: wpBlog(databaseId: { eq: $databaseId }) {
      ...BlogPostContent
    }
    subNav: wpMenu(locations: { eq: $subNavLocation }) {
      ...SubNavigation
    }
    activeSubNavLabel: wpMenuItem(locations: { eq: $subNavLocation }, url: { eq: $activeSubPath }) {
      label
    }
    nextPage: wpBlog(databaseId: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpBlog(databaseId: { eq: $previousPage }) {
      title
      uri
    }
  }
`;

export const fragments = graphql`
  fragment BlogPostContent on WpBlog {
    title
    uri
    dateGmt
    modifiedGmt
    seo {
      ...SeoData
    }
    blogTags {
      nodes {
        name
        uri
      }
    }
    blogPostFields {
      author
      hero {
        image {
          ...HeroImage
        }
        imageMobile {
          ...HeroImage
        }
      }
      postBody
    }
  }
`;
